<template>
  <div class="page">
    <!-- 搜索条件表单 -->
    <el-form class="ser_form" size="small" label-width="80px">
      <el-form-item class="el_form_item" label="运单编号">
        <el-input
          class="el_input"
          v-model="form.truck_tord_num"
          placeholder="货源运单编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="上游编号">
        <el-input
          class="el_input"
          v-model="form.out_trade_num"
          placeholder="货源运单编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="组别编号">
        <el-input
          class="el_input"
          v-model="form.group1_num"
          placeholder="运单组别编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="货主手机">
        <el-input
          class="el_input"
          v-model="form.shipper_tel"
          placeholder="货主手机"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="车主手机">
        <el-input
          class="el_input"
          v-model="form.truck_owner_tel"
          placeholder="车主手机"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="司机手机">
        <el-input
          class="el_input"
          v-model="form.driver_tel"
          placeholder="司机手机"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="车牌号">
        <el-input
          class="el_input"
          v-model="form.truck_plate_num"
          placeholder="货源运单编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="货物名称">
        <el-input
          class="el_input"
          v-model="form.cargo_name"
          placeholder="货物名称搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="支付状态">
        <el-select class="el_input" v-model="form.payed_status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="未支付" value="1"></el-option>
          <el-option label="部分支付" value="2"></el-option>
          <el-option label="支付完毕" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="结算方式">
        <el-select class="el_input" v-model="form.settlement_type" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="按吨结算" value="1"></el-option>
          <el-option label="按方结算" value="2"></el-option>
          <el-option label="按趟结算" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="是否回程">
        <el-select class="el_input" v-model="form.is_need_return" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="需要回程" value="1"></el-option>
          <el-option label="不需回程" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="匹配方式">
        <el-select class="el_input" v-model="form.match_way" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="车主抢单" value="1"></el-option>
          <el-option label="指定后车主接单" value="2"></el-option>
          <el-option label="车主竞价中标" value="3"></el-option>
          <el-option label="车主扫码接单" value="4"></el-option>
          <el-option label="网点线下匹配" value="5"></el-option>
          <el-option label="合作方匹配" value="6"></el-option>
          <el-option label="后台匹配" value="7"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="装货地">
        <el-cascader
          class="el_input"
          v-model="form.case_cities"
          @change="ser_para_case_cities_change"
          placeholder="装货地省市区"
          :options="ser_para.cities"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item class="el_form_item" label="到货地">
        <el-cascader
          class="el_input"
          v-model="form.aim_cities"
          @change="ser_para_aim_cities_change"
          placeholder="到货地省市区"
          :options="ser_para.cities"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item class="el_form_item" label="添加网点">
        <el-select class="el_input" v-model="form.outlets_num" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option
            v-for="(item, index) in ser_para.outlets_list"
            :key="index"
            :label="item.name"
            :value="item.outlets_num"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="审核状态">
        <el-select class="el_input" v-model="form.check_state" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="待审核" value="1"></el-option>
          <el-option label="审核通过" value="2"></el-option>
          <el-option label="审核打回" value="3"></el-option>
          <el-option label="已删除" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item elfrom_item" label="发起时间">
        <div class="block">
          <el-date-picker
            v-model="date_value"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item class="el_form_item elfrom_item"> </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" @click="cargo_ser">查询</el-button>
        <el-button type="primary" @click="ser_para_clear">清空条件</el-button>
        <!-- <el-button type="primary" @click="open_Confirm()">批量通过</el-button> -->
        <!-- <el-button type="primary" @click="see_test">弹框测试</el-button> -->
      </el-form-item>
    </el-form>
    <div class="total_info item">
      <span>共:{{ total.max }}条</span>
      <span>总运费:{{ total.freight_total }}元</span>
      <span>总现金:{{ total.cash_total }}元</span>
      <span>总油费:{{ total.fuel_total }}元</span>
      <!-- <span>总手续费:{{ list.item.sxf_total }}元</span> -->
    </div>
    <el-radio-group
      v-model="trans_status"
      style="margin-bottom: 30px"
      @change="handleClick()"
    >
      <el-radio-button label="1">全部</el-radio-button>
      <el-radio-button label="2">审核中</el-radio-button>
      <!-- <el-radio-button label="3">待接单</el-radio-button> -->
      <el-radio-button label="4">运输中</el-radio-button>
      <el-radio-button label="5">已到货</el-radio-button>
      <el-radio-button label="7">可开票</el-radio-button>
    </el-radio-group>
    <el-radio-group
      v-model="hd_status"
      style="margin-bottom: 30px; margin-left: 20px"
      @change="handleClick()"
    >
      <el-radio-button label="1">不限</el-radio-button>
      <el-radio-button label="2">未上传</el-radio-button>
      <!-- <el-radio-button label="3">待接单</el-radio-button> -->
      <el-radio-button label="3">已上传</el-radio-button>
    </el-radio-group>
    <!-- 表格 -->
    <div class="tab_height">
      <el-table
        :data="list.data"
        :border="true"
        :stripe="true"
        size="small"
        v-loading="list.loading"
        @selection-change="select_table"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column label="运单编号/组别编号/上游单号" width="180px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              (运单) {{ scope.row.truck_tord_num }}
            </div>
            <div class="tab_line_item">(组别) {{ scope.row.group1_num }}</div>
            <div class="tab_line_item">
              (上游) {{ scope.row.out_trade_num }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="装卸货地信息">
          <template slot-scope="scope">
            <div class="tab_line_item" style="margin-top: 10px">
              (发货地省市区) {{ scope.row.case_prov }} /
              {{ scope.row.case_city }} / {{ scope.row.case_county }}
            </div>
            <div class="tab_line_item">
              (&nbsp;详情)
              {{ scope.row.case_addr ? scope.row.case_addr : "无" }}
            </div>
            <div class="tab_line_item" style="margin-top: 15px">
              (卸货地省市区) {{ scope.row.aim_prov }} /
              {{ scope.row.aim_city }} / {{ scope.row.aim_county }}
            </div>
            <div class="tab_line_item">
              (&nbsp;详情)
              {{ scope.row.aim_addr ? scope.row.aim_addr : "无" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="名称/重量/体积" width="220px">
          <template slot-scope="scope">
            <div class="tab_line_item">名称: {{ scope.row.cargo_name }}</div>
            <div class="tab_line_item">
              重量:
              {{
                scope.row.cargo_weight == 0
                  ? "未设置"
                  : scope.row.cargo_weight + "吨"
              }}
            </div>
            <div class="tab_line_item">
              体积:
              {{
                scope.row.cargo_volume == 0
                  ? "未设置"
                  : scope.row.cargo_volume + "方"
              }}
            </div>
            <div class="tab_line_item">
              距离: {{ scope.row.distance }} 公里
              <!-- <el-button
                class="btn_left"
                @click="map_View(scope.row)"
                size="mini"
                type="text"
                >点击查看</el-button
              > -->
            </div>
            <!-- <el-button
              class="btn_left"
              size="mini"
              type="text"
              @click="see_trajectory(scope.row)"
              >查看轨迹</el-button
            > -->
          </template>
        </el-table-column>
        <el-table-column label="运费/支付状态" width="220px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              总运费: {{ scope.row.cost_info.freight_total }}
            </div>
            <div class="tab_line_item">
              现金运费: {{ scope.row.cost_info.cash_total }}
            </div>
            <div class="tab_line_item">
              油费: {{ scope.row.cost_info.fuel_total }}
            </div>
            <div class="tab_line_item">
              已付现金: {{ scope.row.cost_info.cash_payed }}
            </div>
            <div class="tab_line_item">
              已付油费: {{ scope.row.cost_info.fuel_cost_payed }}
            </div>
            <div class="tab_line_item">
              状态: {{ scope.row.payed_status_text }}
            </div>
            <div class="tab_line_item">
              操作:
              <el-button
                class="btn_left"
                @click="tord_payed_list(scope.row)"
                size="mini"
                type="text"
                >支付记录</el-button
              >
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="其他信息" width="140px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              结算: {{ scope.row.settlement_type_text }}
            </div>
            <div class="tab_line_item">
              回程: {{ scope.row.is_need_return_text }}
            </div>
            <div class="tab_line_item">
              匹配: {{ scope.row.match_way_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="货主信息" width="180px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              姓名: {{ scope.row.shipper_info.name }}
            </div>
            <div class="tab_line_item">
              电话: {{ scope.row.shipper_info.tel }}
            </div>
            <div class="tab_line_item">
              公司: {{ scope.row.shipper_info.company_name }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="车主/司机/货车信息" width="200px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              车主: {{ scope.row.truck_owner_info.name }}/{{
                scope.row.truck_owner_info.tel
              }}
            </div>
            <div class="tab_line_item">
              司机: {{ scope.row.driver_info.name }}/{{
                scope.row.driver_info.tel
              }}
            </div>
            <div class="tab_line_item">
              车牌: {{ scope.row.truck_plate_num }}
            </div>
            <div class="tab_line_item">
              类型: {{ scope.row.truck_info.truck_type_name_text }}
            </div>
            <div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作网点" width="160px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              姓名:
              {{
                scope.row.outlets_info.name ? scope.row.outlets_info.name : "无"
              }}
            </div>
            <div class="tab_line_item">
              电话:
              {{
                scope.row.outlets_info.tel ? scope.row.outlets_info.tel : "无"
              }}
            </div>
            <div class="tab_line_item">
              网点:
              {{
                scope.row.outlets_info.outlets_name
                  ? scope.row.outlets_info.outlets_name
                  : "无"
              }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="运单状态/时间信息" width="220px">
          <template slot-scope="scope">
            <div class="tab_line_item">状态: {{ scope.row.status_text }}</div>
            <div class="tab_line_item">
              添加: {{ scope.row.creat_time_text }}
            </div>
            <div class="tab_line_item">
              发车: {{ scope.row.start_time_text }}
            </div>
            <div class="tab_line_item">
              到货: {{ scope.row.arrived_time_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" width="100px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              状态: {{ scope.row.check_state_text }}
            </div>
            <div
              class="tab_line_item"
              v-if="scope.row.check_state_text != '审核通过'"
            >
              原因: {{ scope.row.check_info ? scope.row.check_info : "无" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ocr审核结果" width="100px">
          <template slot-scope="scope">
            <div class="tab_line_item">
               {{ scope.row.auto_check_info ? scope.row.auto_check_info :'无'}}
            </div>
          </template>
        </el-table-column>

        <!-- 操作行 -->
        <el-table-column fixed="right" label="操作" width="80" align="center">
          <template slot-scope="scope">
            <!-- v-if="scope.row.check_state == 1" -->
            <!-- <div>
              <el-button
                v-if="scope.row.status == 2"
                class="btn_left"
                @click="tord_arrived(scope.row)"
                size="mini"
                type="text"
                >到货</el-button
              >
            </div> -->
            <!-- <div>
              <el-button
                @click="tord_edit(scope.row)"
                class="btn_left"
                size="mini"
                type="text"
                >修改</el-button
              >
            </div> -->
            <div>
              <el-button
                @click="voucher_show(scope.row.truck_tord_num)"
                class="btn_left"
                size="mini"
                type="text"
                >凭证</el-button
              >
            </div>
            <!-- <el-button @click="tord_del(scope.row)" class="btn_left" size="mini" type="text">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination
      class="margin-top-xl"
      @size-change="page_size_change"
      @current-change="page_current_change"
      :current-page="page.p"
      :page-sizes="[10, 100, 200, 500]"
      :page-size="page.num"
      :total="list.total * 1"
      layout="total, sizes,prev, pager, next, jumper"
    >
    </el-pagination>

    <!-- 凭证界面 -->
    <voucher
      :truck_tord_num="voucher.truck_tord_num"
      :is_show="voucher.is_show"
    ></voucher>

    <!-- 批量审核页面 -->
    <!-- <openConfirm
      :truck_tord_num="openConfirm.truck_tord_num"
      :is_show="openConfirm.is_show"
    ></openConfirm> -->
    <!-- 地图轨迹详情查看 -->
    <!-- <el-dialog :visible.sync="map.is_show" width="80%" top="10">
      <Mapsee
        :start="map.start"
        :end="map.end"
        :start_province="map.start_province"
        :end_province="map.end_province"
        :num="map.num"
      ></Mapsee>
    </el-dialog> -->
    <!-- 查看轨迹弹出框 -->
    <!-- <el-dialog :visible.sync="maptrajectory.is_show" width="80%" top="0">
      <maptrajectory
        :plate_num="maptrajectory.plate_num"
        :start_province="maptrajectory.start_time"
        :end_province="maptrajectory.end_time"
        :num="maptrajectory.num"
        :transport_staer="maptrajectory.start"
        :transport_end="maptrajectory.end"
        :aim_prov="maptrajectory.aim_prov"
        :case_prov="maptrajectory.case_prov"
      ></maptrajectory>
    </el-dialog> -->
    <!-- 审核不通过原因 -->
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
import voucher from "../../transit_real_time/voucher/voucher_before_end.vue";
// import openConfirm from "../tord/openConfirm.vue";
// import maptrajectory from "../../transit_real_time/tord/maptrajectory.vue";
// import Mapsee from "../../transit_real_time/tord/mapSee.vue";
export default {
  components: {
    voucher,
    // Mapsee,
    //maptrajectory,
  },
  data() {
    return {
      //地图查看参数
      // map: {
      //   num: 0,
      //   is_show: false,
      //   start: "",
      //   end: "",
      //   start_province: "",
      //   ed_province: "",
      // },
      total: {},
      //查看轨迹参数
      // maptrajectory: {
      //   type: 0,
      //   num: 0,
      //   is_show: false,
      //   plate_num: "", //车牌号
      //   start_time: "", // 开始时间
      //   end_time: "", // 结束时间
      //   start: "", //发车
      //   end: "", //到货
      //   aim_prov: "", //到货地
      //   case_prov: "", //发货地
      // },
      //搜索条件参数
      ser_para: {
        // cargo_type:[],//货物类型
        outlets_list: [], //网点列表
        cities: regionData, //省市区数据
      },
      trans_status: "1",
      hd_status: "1",
      //搜索条件
      form: {
        truck_tord_num: "", //货源编号
        out_trade_num: "", //上游编号
        group1_num: "", //组别编号
        shipper_tel: "", //货主手机号
        truck_owner_tel: "", //车主手机号
        driver_tel: "", //司机手机号
        truck_plate_num: "", //车牌号
        cargo_name: "", //货物名称
        payed_status: "", //支付状态
        settlement_type: "", //结算方式
        is_need_return: "", //是否回程
        case_prov: "", //装货地省份
        case_city: "", //装货地城市
        case_county: "", //装货地县区
        aim_prov: "", //到货地省份
        aim_city: "", //到货地城市
        aim_county: "", //到货地县区
        check_state: "", //审核状态
        case_cities: null, //装货地省市县
        aim_cities: null, //到货地省市县
        outlets_num: "", //网点编号
        creat_time_start: "", // 开始时间
        creat_time_end: "", // 结束
      },
      ignore: "忽略",
      date_value: "", //时间选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      //表格数据
      list: {
        loading: false, //loading特效
        data: [], //列表数据
        total: 0, //列表总记录数
        distance: 0, //距离
      },
      //页码信息
      page: {
        need_num: 1,
        num: 10,
        p: 1,
      },

      //凭证相关
      voucher: {
        is_show: 0,
        truck_tord_num: "",
      },
      //修改相关
      edit: {
        is_show: 0,
        tord_info: {},
      },
    };
  },
  created() {
    //读取网点列表
    this.get_outlets_list();

    //读取货源
    this.get_page_data();
  },
  methods: {
    //数据勾选
    select_table(item) {
      var truck_tord_num = [];
      item.forEach((item) => {
        truck_tord_num.push(item.truck_tord_num);
      });
      this.openConfirm.truck_tord_num = truck_tord_num;
    },
    //tabs切换
    handleClick() {
      this.page.p = 1
      this.get_page_data();
    },
    // 查看距离详情弹出框
    // map_View(item) {
    //   this.map.start_province =
    //     item.case_prov + item.case_city + item.case_county;
    //   this.map.end_province = item.aim_prov + item.aim_city + item.aim_county;
    //   if (item.case_city == item.case_county) {
    //     this.map.start = item.case_city + item.case_county + item.case_addr; // 发货地详情
    //   } else {
    //     this.map.start = item.case_city + item.case_county;
    //   }
    //   if (item.aim_city == item.aim_county) {
    //     this.map.end = item.aim_city + item.aim_county + item.aim_addr; //卸货地详情
    //   } else {
    //     this.map.end = item.aim_city + item.aim_county;
    //   }
    //   this.map.num++;
    //   this.map.is_show = true;
    // },
    // see_test() {
    //   Notification.requestPermission((status) => {
    //     console.log(status);
    //     if (status === "granted") {
    //       let notify = new Notification("骏工物联提示", {
    //         // icon: "../../res/img/logo.png",
    //         body: "您有新的待审核运单",
    //       });
    //       // 点击时桌面消息时触发
    //       notify.onclick = () => {
    //         // 跳转到当前通知的tab,如果浏览器最小化，会将浏览器显示出来
    //         window.focus();
    //       };
    //     }
    //   });
    // },
    // 货车
    _check_view_close() {
      //上报界面关闭回调

      //复位
      this.truck_info.is_show = 0;

      //清空数据
      this.truck_info.data = [];

      //刷新页面
      this.get_page_data();
    },
    // 司机
    check_view_close() {
      //上报界面关闭回调

      //复位
      this.driver_info.is_show = 0;

      //清空数据
      this.driver_info.data = [];

      //刷新页面
      this.get_page_data();
    },
    //查询公里数
    Kilometers() {
      var _this = this;
      this.list.data.forEach((item, index) => {
        var start_cit = item.case_city; // 开始省份
        var end_city = item.aim_city; //到货省份
        var start = "";
        var end = "";
        if (item.case_city == item.case_county) {
          start = item.case_city + item.case_county + item.case_addr; // 发货地详情
        } else {
          start = item.case_city + item.case_county;
        }
        if (item.aim_city == item.aim_county) {
          end = item.aim_city + item.aim_county + item.aim_addr; //卸货地详情
        } else {
          end = item.aim_city + item.aim_county;
        }
        var start_province = item.case_prov + item.case_city + item.case_county; //装货地省市区
        var end_province = item.aim_prov + item.aim_city + item.aim_county; //卸货地省市区
        var driving = new AMap.Driving({});
        // 高德地图api 查询公里数
        driving.search(
          [
            { keyword: start, city: start_province },
            { keyword: end, city: end_province },
          ],
          function (status, result) {
            if (
              start_cit == end_city &&
              result.routes[0].distance / 1000 > 400
            ) {
              _this.list.data[index].distance = 0;
            }
            if (_this.list.data[index].distance == 0) {
              _this.list.data[index].distance =
                result.routes[0].distance / 1000;
            }
          }
        );
      });
    },
    //轨迹查询
    // see_trajectory(item) {
    //   this.maptrajectory.case_prov = item.case_prov;
    //   this.maptrajectory.aim_prov = item.aim_prov;
    //   this.maptrajectory.is_show = true;
    //   this.maptrajectory.start = item.start_time_text;
    //   this.maptrajectory.end = item.arrived_time_text;
    //   var arrived_date = item.start_time_text;
    //   arrived_date = new Date(item.start_time_text).getTime() / 1000;
    //   var start_time = parseInt(arrived_date);
    //   var time_start = start_time.toString().length;
    //   // 判断长度是否大于10  elemen弹框会自动缓存
    //   if (time_start >= 10) {
    //     this.maptrajectory.start_time = start_time;
    //   }
    //   var end_date = item.arrived_time_text;
    //   end_date = new Date(item.arrived_time_text).getTime() / 1000;
    //   var end_time = parseInt(end_date);
    //   var time_end = end_time.toString().length;
    //   if (time_end >= 10) {
    //     this.maptrajectory.end_time = end_time;
    //   }
    //   this.maptrajectory.plate_num = item.truck_info.truck_plate_num;
    //   this.maptrajectory.num++;
    // },

    //完结相关操作
    tord_edit_sub(item) {
      //关闭弹出层
      this.edit.is_show = 0;

      //刷新
      this.get_page_data();
    },
    tord_edit(item) {
      this.edit.is_show++;
      this.edit.tord_info = item;
    },

    //凭证相关操作
    voucher_show(truck_tord_num) {
      //显示凭证
      this.voucher.is_show++;
      this.voucher.truck_tord_num = truck_tord_num;
    },

    //运单删除
    tord_del(item) {
      //提示
      this.$my.other.confirm({
        content: "您的操作是'删除运单',请确认",
        confirm: () => {
          //提交
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_del_by_admin",
              truck_tord_num: item.truck_tord_num,
            },
            callback: (data) => {
              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },
    // 打开支付记录
    // tord_payed_list(item) {
    //   this.Payment.id = item.id;
    //   this.Payment.is_show++;
    // },
    // 审核不通过原因
    open(item) {
      this.$prompt("请输入打回原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_check_unpass_by_admin",
              truck_tord_num: item.truck_tord_num,
              fail_reason: value,
            },
            callback: (data) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              //刷新本页
              this.get_page_data();
            },
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //确认到货
    tord_arrived(item) {
      //提示
      this.$my.other.confirm({
        content: "您的操作是'确认到货',请确认",
        confirm: () => {
          //提交
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_arrived_by_admin",
              truck_tord_num: item.truck_tord_num,
            },
            callback: (data) => {
              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },

    //清空查询条件
    ser_para_clear() {
      this.form = {
        truck_tord_num: "", //货源编号
        driver_tel: "", //车主手机号
        truck_plate_num: "", //车牌号
        cargo_name: "", //货物名称
        cargo_type: "", //货物性质
        case_prov: "", //装货地省份
        case_city: "", //装货地城市
        case_county: "", //装货地县区
        aim_prov: "", //到货地省份
        aim_city: "", //到货地城市
        aim_county: "", //到货地县区
        case_cities: null, //装货地省市县
        aim_cities: null, //到货地省市县
      };
      this.get_page_data();
    },

    //发货地省市县选择器
    ser_para_case_cities_change(event) {
      this.form.case_prov = CodeToText[event[0]];
      this.form.case_city = CodeToText[event[1]];
      this.form.case_county = CodeToText[event[2]];
    },

    //到货地省市县选择器
    ser_para_aim_cities_change(event) {
      this.form.aim_prov = CodeToText[event[0]];
      this.form.aim_city = CodeToText[event[1]];
      this.form.aim_county = CodeToText[event[2]];
    },

    //搜索
    cargo_ser() {
      if (this.date_value) {
        this.form.creat_time_start = parseInt(this.date_value[0] / 1000);
        this.form.creat_time_end = parseInt(this.date_value[1] / 1000);
        this.get_page_data();
      }else{
         this.form.creat_time_start = '',
         this.form.creat_time_end='',
         this.get_page_data();
      }
    },

    //页宽变化
    page_size_change(num) {
      //置入页码
      this.page.num = num;

      //读取
      this.get_page_data();
    },

    //页码变化
    page_current_change(p) {
      //置入页码
      this.page.p = p;

      //读取
      this.get_page_data();
    },

    //获取货源列表
    get_page_data() {
      //加载中...
      // if (this.list.loading) {
      //   return;
      // }

      //置为加载中
      //this.list.loading = true;

      //读取数据
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_check_list_admin",
          ...this.form,
          ...this.page,
          trans_status: this.trans_status,
          hd_status: this.hd_status,
        },
        callback: (data) => {
          //加载完毕
          this.list.loading = false;

          this.total = data.total;

          //总数
          this.list.total = data.max;

          //预处理
          for (let item of data.list) {
            //数据空值初始化
            item.truck_info = item.truck_info ? item.truck_info : {};
            item.driver_info = item.driver_info ? item.driver_info : {};
            item.truck_owner_info = item.truck_owner_info
              ? item.truck_owner_info
              : {};

            //成交时间
            item.creat_time_text = this.$my.other.totime(item.creat_time);

            //发车时间
            if (item.start_time > 0) {
              //已发车
              item.start_time_text = this.$my.other.totime(item.start_time);
            } else item.start_time_text = "未发车";

            //到货时间
            if (item.arrived_time > 0) {
              //已到货
              item.arrived_time_text = this.$my.other.totime(item.arrived_time);
            } else item.arrived_time_text = "未到货";

            //装货时间
            if (item.case_date_start == 0 && item.case_date_end != 0) {
              //只有止点

              item.case_date =
                this.$my.other.totime(item.case_date_end) + "以前";
            } else if (item.case_date_start != 0 && item.case_date_end == 0) {
              //只有起点

              item.case_date =
                this.$my.other.totime(item.case_date_start) + "以后";
            } else if (item.case_date_start != 0 && item.case_date_end != 0) {
              //都有

              item.case_date =
                this.$my.other.totime(item.case_date_start) +
                " ~ " +
                this.$my.other.totime(item.case_date_end);
            } else if (item.case_date_start == 0 && item.case_date_end == 0) {
              //都没有

              item.case_date = "未设置";
            }

            //卸货时间
            if (item.aim_date_start == 0 && item.aim_date_end != 0) {
              //只有止点

              item.aim_date = this.$my.other.totime(item.aim_date_end) + "以前";
            } else if (item.aim_date_start != 0 && item.aim_date_end == 0) {
              //只有起点

              item.aim_date =
                this.$my.other.totime(item.aim_date_start) + "以后";
            } else if (item.aim_date_start != 0 && item.aim_date_end != 0) {
              //都有

              item.aim_date =
                this.$my.other.totime(item.aim_date_start) +
                " ~ " +
                this.$my.other.totime(item.aim_date_end);
            } else if (item.aim_date_start == 0 && item.aim_date_end == 0) {
              //都没有

              item.aim_date = "未设置";
            }

            //运单状态
            switch (item.status) {
              case "1":
                item.status_text = "待发车";
                break;
              case "2":
                item.status_text = "运输中";
                break;
              case "3":
                item.status_text = "已到货";
                break;
            }

            //结算方式
            switch (item.settlement_type) {
              case "1":
                item.settlement_type_text = "按吨数";
                break;
              case "2":
                item.settlement_type_text = "按方数";
                break;
              case "3":
                item.settlement_type_text = "按趟";
                break;
            }

            //是否回程
            switch (item.is_need_return) {
              case "1":
                item.is_need_return_text = "需要回程";
                break;
              case "2":
                item.is_need_return_text = "不需要回程";
                break;
            }

            //匹配方式
            switch (item.match_way) {
              case "1":
                item.match_way_text = "车主抢单";
                break;
              case "2":
                item.match_way_text = "指定后车主接单";
                break;
              case "3":
                item.match_way_text = "车主竞价中标";
                break;
              case "4":
                item.match_way_text = "车主扫码接单";
                break;
              case "5":
                item.match_way_text = "网点线下匹配";
                break;
              case "6":
                item.match_way_text = "合作方匹配";
                break;
              case "7":
                item.match_way_text = "后台匹配";
                break;
            }

            //运费
            if (item.cost_info.freight_total) {
              item.cost_info.freight_total_text =
                item.cost_info.freight_total + " 元";
            } else item.cost_info.freight_total_text = "未设置";

            //已付总额
            item.cost_info.has_payed_total_text =
              (
                parseFloat(item.cost_info.cash_payed) +
                parseFloat(item.cost_info.fuel_cost_payed) +
                parseFloat(item.cost_info.road_toll_payed)
              ).toFixed(2) + " 元";

            //匹配方式
            switch (item.payed_status) {
              case "1":
                item.payed_status_text = "未支付";
                break;
              case "2":
                item.payed_status_text = "部分支付";
                break;
              case "3":
                item.payed_status_text = "支付完毕";
                break;
            }
            //审核状态
            switch (item.check_state) {
              case "1":
                item.check_state_text = "待审核";
                break;
              case "2":
                item.check_state_text = "审核通过";
                break;
              case "3":
                item.check_state_text = "审核打回";
                break;
              case "4":
                item.check_state_text = "已删除";
                break;
            }
            //运费支付状态
            switch (item.payed_status) {
              case "1":
                item.payed_status_text = "未支付";
                break;
              case "2":
                item.payed_status_text = "部分支付";
                break;
              case "3":
                item.payed_status_text = "支付完毕";
                break;
            }

            //货车类型
            if (
              item.truck_info.truck_type_name &&
              item.truck_info.truck_type_name2
            ) {
              item.truck_info.truck_type_name_text =
                item.truck_info.truck_type_name +
                " / " +
                item.truck_info.truck_type_name2;
            }
          }
          // console.log(data.list);
          //渲染
          this.list.data = data.list;
          this.Kilometers();
        },
      });
    },

    //读取网点列表
    get_outlets_list() {
      //调用接口
      this.$my.net.req({
        data: {
          mod: "truck_business_outlets",
          ctr: "outlets_list_admin",
          num: 1000,
        },
        callback: (data) => {
          //渲染
          this.ser_para.outlets_list = data.list;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // height: calc(100% - 40px);
  overflow: auto;
}
.ser_form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tab_height {
  height: calc(100% - 184px);
}
.el_form_item {
  width: 275px;
}
.el_input {
  width: 94%;
}
.btn_left {
  margin: 0;
}
.total_info {
  text-align: right;
  font-size: 12px;
  margin-bottom: 24px;
  margin-right: 1px;
  margin-top: 10px;
  color: #606266;
  span {
    margin-left: 20px;
  }
}
.item {
  font-weight: 800;
}
</style>